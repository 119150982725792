body, html {
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
}

.container {
  text-align: center;
}

.social {
  text-align: center;
  color: #363435;
  margin-top: 8px;
}

.social svg {
  cursor: pointer;
}

.social .youtube {
  margin-left: 8px;
}